
footer{
    display: flex;
    color: white;
    width: 100%;
    height: 70px;
    justify-content: center;
    margin-top: 100px;
    background-color: #567086;
    padding: 20px;
   

}