.header{
    width: 100%;
    display: flex;
    align-items: center;
    height: 130px;
    background-color: #567086;
    color: white;
    position: relative;
    box-shadow: rgb(255 255 255 / 10%) 0 1px 1px 1px;
}
.left-side{
    padding-left: 50px;
    font-size: 20px;
    width: 20%;
}



.left-side h1{

    font-size: 25px;
    width: 100%;
   
}



.right-side{
    display: flex;

    padding-left: 100px;
}



.link-tab{
    width: 150px;
    height: 130px;
   
   
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}



.link-tab:hover{
    color: white;
    background-color: #f5a623;
}

.link-tab:active{
    color: white;
    background-color: #f5a623;


}



.link-deco{
    text-decoration: none;
    color: white;
    text-align: center;
}

.logobtn{
    padding-left: 30px;
    right: 5%;
    font-size: 18px;
    bottom: 0%;
}

.right-side2{
    display: none;
}
.togglebtn{
    display: none;
}
#mobile i{
  color: #fff;
  align-items: center;
  
}

#mobile i{
  color: #fff;
  align-items: center;
  position: absolute;
  right: 40px;
  top: 50px;
  display: none;

}

#mobileLogout{
    display: none;
}


 @media  screen and (max-width:768px){ 
   
    .left-side{
        padding-left: 30px;
        font-size: 30px;
        width: 80%;
    }
   
    .left-side h1{

        font-size: 25px;
        width: 100%;
       
    }
    .right-side{
        position: fixed;
        flex-direction: column;
        /* background-color: red; */
        top: 100px;
        right: 35px;
        height: 300px;
        transition: 0.4s ease-in-out;
    }
    .right-side.active{
        right: -50px;
    }



   .link-text{
    display: none;
    width: 50%;
   }
   .link-tab{
    width: 50px;
    height: 130px;
   
   
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
#mobile i{
    
    display: block;
  
  }

  
#mobileLogout{
    display: block;
    padding-left: 10px;
}
.logobtn{
    display: none;
}

    
   

   
 } 
 
