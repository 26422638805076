.dashboard{
    display: flex;
    flex-direction: column;

    justify-content: space-between;
    align-items: center;
    margin: 100px auto ;
    padding-left: 70px;
    padding-right: 70px; 
    color: white;
   

}




.first-section{

    display: flex;
    justify-content: space-between;
    height: 50vh;
    width: 100%;
    gap: 5%;
    margin:auto;

   


}

.first-left-section{
    padding: 40px;
    padding-left: 40px;
     height: 100%;
    width: 45%;
    background-color: #435c70;
    color: white;

}

.first-right-section{
    padding: 40px;
     height: 100%;
    width: 45%;
    background-color: #435c70;
    color: white;
}










.second-section{

    display: flex;
    justify-content: space-between;
    height: 50vh;
    width: 100%;
   gap: 5%;
    margin:auto;
    margin-top: 100px;
   


}

.second-left-section{
    padding: 40px;
    padding-left: 40px;
     height: 100%;
    width: 45%;
    background-color: #435c70;
    color: white;
}

.second-right-section{
   
     height: 100%;
    width: 45%;

}







.third-list-section{
  display: flex;
    justify-content: space-between;
    height: 70vh;
    width: 100%;
    margin:auto;
    margin-top: 100px;
    
   

}



@media only screen and (max-width: 800px) {
    .dashboard{
       
    
        justify-content: space-between;
        align-items: left;
        margin: 100px auto ;
        padding-left: 10px; 
         padding-right: 10px; 
        color: white;
        gap: 100px;
       
    
    }
   
    
.first-section{

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    width: 100vw;
   
}

.first-left-section{
    width: 95%;
    margin-right: 20px;
    
}

.first-right-section{
     width: 95%;
    margin-right: 20px;
    
}




.second-section{

  
    justify-content: space-between;
    flex-direction: column;
    height: auto;
    width: 100%;
   gap: 5%;
    margin:auto;
    margin-top: 100px;
   


}
.second-left-section{
    width: 100%;
    margin-right: 20px;
    margin-bottom: 100px;
    
}

.second-right-section{
     width: 100%;
     height: 500px;
    
    
}


.third-list-section{
    display: flex;
      justify-content: space-between;
      height: 600px;
      width: 100%;
      margin:auto;
      margin-top: 100px;
      
     
  
  }

.orderList{
  
    width: 100%;
    padding: 20px;
    overflow-x: scroll;
    overflow-y: scroll;
}


.ordertable-lists{

    background-color: #50697f;
    height: 100px;
    width: auto;
    border: none;
    scrollbar-width: 'thin';
    scrollbar-color: 'purple yellow';
    overflow: 'scroll';

    border-bottom: 2px solid #486177;
  
}




}












.orderList{
    background-color: #435c70;
    color:white;
    width: 100%;
    padding: 20px;
 
    overflow-y: scroll;
     scrollbar-width: 'thin';
      scrollbar-color: 'purple yellow';
      overflow: 'scroll';
      


 
  
}


.orderList::-webkit-scrollbar {
    width: 12px;
 
    background-color: #50697f;
  }
  
  .orderList::-webkit-scrollbar-thumb {
    background-color: #486177;
    border-radius: 10px;
    width: 4px;
  }




table{
    padding: 40px;
}

.ordertable-heading{
    background-color: #486177;
    height: 100px;
    width: auto;
    border: none;
   
}

.ordertable-heading th{
    width: 300px;
    text-align: center;
    padding-top: 30px;
}


.ordertable-lists{

    background-color: #50697f;
    height: 100px;
    width: auto;
    border: none;
    scrollbar-width: 'thin';
    scrollbar-color: 'purple yellow';
    overflow: 'scroll';

    border-bottom: 2px solid #486177;

}


.ordertable-lists td{
    width: 300px;
    text-align: center;
    padding-top: 30px;
    
}



/* --------------- */














/* ------------------notification css  */

.notification-bar{
    background-color: #435c70;
    height: 100%;
    padding: 40px;
    color: white;
    overflow-x: hidden;
    overflow-y: scroll;

}


.notification-bar::-webkit-scrollbar {
    width: 12px;
 
    background-color: #50697f;
  }
  
  .notification-bar::-webkit-scrollbar-thumb {
    background-color: #486177;
    border-radius: 10px;
    width: 4px;
  }


.notification-bar h3{
   
    padding: 20px; 

}

.notification-list{
    display: flex;
    gap: 30px;
    background-color: #50697f;
    color:white;
    width: 100%;
    padding: 20px;
    margin-bottom: 50px;
    /* overflow-x: hidden;
    overflow-y: scroll;  */


  
  
}

.notification-list img{
    height: 80px;
    width: 80px;
    border-radius: 50%;
}

.notmsg{
    display: flex;
    flex-direction: column;
    gap: 20px;
}









/* --------LINE CHART CSS  ---- */