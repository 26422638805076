
.login-box{
    display: flex;
    flex-direction: column;
    gap: 5%;
    width: 500px;
    height: 600px;
     background-color: #435c70;
    margin: auto;
    margin-top: 100px;
    color: white;
    text-align: center;
    padding: 40px;
   
}

.form-input{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 66%;
}

.form-input-box{
    padding: 20px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #54657d;
    color: #fff;
    border: 0;
    font-size: 20px;
}

.form-input-box:focus{
    outline: 1px solid white;
}

.form-button{
    padding: 20px;
    height: 50px;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f5a623;
    color: #fff;
    border: 0;
    font-size: 16px;
    text-align: center;
    font-weight: 500;

}


.form-button:hover{
 

    background-color: #435c70;
    color:#f5a623;
    border: 2PX solid #f5a623;
   

}



@media  screen and (max-width:768px){ 
   
    .login-box{
      
        gap: 5%;
        width: 300px;
        height: 500px;
         background-color: #435c70;
        margin: auto;
        margin-top: 100px;
        color: white;
       
        padding: 20px;
      
       
    }
    .form-input{
        display: flex;
        flex-direction: column;
        text-align: left;
        gap: 16%;
    }
    
    .form-input-box{
        padding: 20px;
        height: 30px;
        margin-top: 10px;
        margin-bottom: 10px;
        background-color: #54657d;
        color: #fff;
        border: 0;
        font-size: 13px;
    }
    
}
