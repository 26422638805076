*{
    margin: 0;padding: 0;
    box-sizing: border-box;
}

body{
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    /* background-color: #fff; */
    background-color: #4e657a;
    scroll-behavior: smooth;
    
}

 body::-webkit-scrollbar {
    width: 5px;
    background-color: #4e657a;
  }
  
  body::-webkit-scrollbar-thumb {
    background-color: #4e657a;
    border-radius: 0px;
    width: 4px;
  }



 